// Helper function to handle upserting ChatSessions and inserting into aiquestionire
export const handleChatSessionUpdate = async ({
  chatSessionId,
  updatedChatMessages,
  chatMessageId,
  currentUserId,
  question,
  answer,
  tags,
  supabase,
}) => {
  try {
    // Upsert ChatSessions with updated messages and ensure user_id is included
    const { error: upsertError } = await supabase.from('chatsessions').upsert({
      id: chatSessionId, // Use chat session ID for upsert
      chat_messages: updatedChatMessages, // Update with new messages
      user_id: currentUserId, // Ensure user ID is set for the chat session
    });

    if (upsertError) {
      throw new Error(`Failed to upsert ChatSessions: ${upsertError.message}`);
    }

    // Insert into AIQuestionire
    const { error: aiQuestionireError } = await supabase.from('aiquestionire').insert({
      chat_reference_id: chatMessageId, // Link to the chat session
      author_id: currentUserId,
      question,
      answer,
      tags,
    });

    if (aiQuestionireError) {
      throw new Error(`Failed to insert into AIQuestionire: ${aiQuestionireError.message}`);
    }
  } catch (error) {
    console.error('Error in handleChatSessionUpdate:', error);
    throw error; // Re-throw error to handle it in the calling function
  }
};
